import {
  GET_MAPPED_ASSESSMENT,
  CREATE_UNREGISTERED_USER_ASSESSMENTS,
  CREATE_UNREGISTERED_USER_RESPONSES,
  GET_BRAND_ASSESSMENT_URL,
  CREATE_BRAND_USER_URL,
  CREATE_UPDATE_QUESTION_RESPONSE_URL,
  GET_QUESTIONNAIRE_RESPONSE_URL,
  CREATE_UNREGISTERED_USER_ASSESSMENT_RESPONSES,
  GET_BRAND_USER_URL
} from './url.service';
import * as HttpService from "./http.service";

const ApiUrl = process.env.NEXT_PUBLIC_API_URL;


export const mappedAssessments = (brandId='') => {
  return HttpService.getWithAuth(GET_MAPPED_ASSESSMENT(brandId))
};

export const getQuestionaireByContentId = (contentId, token = '') => {
  return HttpService.getWithAuth(`${ApiUrl}/questionnaires/contents/${contentId}`, token);
};

export const createUnregisteredUserAssessment = (details) => {
  return HttpService.postWithOutAuth(CREATE_UNREGISTERED_USER_ASSESSMENTS(), details);
};

export const createUnregisteredUserResponses = (unregisteredUserId, details) => {
  return HttpService.postWithAuth(CREATE_UNREGISTERED_USER_RESPONSES(unregisteredUserId), details);
};

export const getBrandAssessment = (brandAssessmentId) => {
  return HttpService.getWithAuth(GET_BRAND_ASSESSMENT_URL(brandAssessmentId))
}

export const createBrandUser = (details) => {
  return HttpService.postWithOutAuth(CREATE_BRAND_USER_URL(), details)
}

export const createUpdateQuestionResp = (details, token) => {
  return HttpService.postWithAuthToken(CREATE_UPDATE_QUESTION_RESPONSE_URL, details, token)
}


export const getQuestionnaireResp = (questionnaireId, brandId, token = '') => {
  return HttpService.getWithAuth(GET_QUESTIONNAIRE_RESPONSE_URL(questionnaireId, brandId), token)
}

export const getUnregisteredUserAssessmentResponses = (unregisteredUserId, assessmentId) => {
  return HttpService.getWithAuth(CREATE_UNREGISTERED_USER_ASSESSMENT_RESPONSES(unregisteredUserId, assessmentId))
}

export const getBrandUserInfo = (brandId, logginOption, identifier) => {
  return HttpService.getWithAuth(GET_BRAND_USER_URL(brandId, logginOption, identifier))
}
