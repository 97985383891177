import React from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'

function Brand({ brand,isLoading }) {
    const router = useRouter();

    return (
        <>
            {
                !isLoading ? (
                    <div className='min-h-screen flex items-center justify-center p-4'>
                        {
                            brand ? (
                                <div className='w-full max-w-2xl text-center shadow p-8 border rounded-lg'>
                                    <h1 className='mb-2 font-dosis -mt-2'>{brand?.name}</h1>
                                    <div className='mt-8'>
                                        <Image
                                            src={brand?.url || '/images/landing.svg'}
                                            width={450}
                                            height={300}
                                            className='rounded-2xl'
                                            objectFit='cover'
                                        />
                                        <h4 className='font-Nunito font-normal text-lg md:text-2xl text-grey-800 mb-2 mt-3'>Are you ready? Let’s check your score!</h4>
                                        <p className='text-sm md:text-base text-grey-800 max-w-sm mx-auto'>Designed by our team of clinical psychologists,  You would also come to know the mildness or severity of it if present!</p>
                                        <button className='bg-lightSpray rounded-full py-3.5 px-10 w-full max-w-xs mx-auto mt-10 font-semibold text-base' onClick={() => router.push(`${brand?.id}/assessments/`)}>Get Started</button>
                                    </div>
                                </div>
                            ) : (
                                <div className='text-xl font-bold text-center my-4'>Brand doesn't exists!</div>
                            )
                        }

                    </div>
                ) : <div className='text-xl font-bold text-center my-4'>Loading ...</div>
        }
        </>

    )
}

export default Brand