import { getBrandAssessment } from '@services/assessment.service'
import React,{useState,useEffect} from 'react'
import Brand from '@components/Brand';
import { useRouter } from 'next/router';

function Index() {
    const router=useRouter()
    const [brandAssessment,setBrandAssessment]=useState(null)
    const [isLoading,setIsLoading]=useState(true)
    useEffect(() => {
        if(router.isReady){
            getBrandAssessment(router.query.brandId).then(response=>{
                if(response.status) setBrandAssessment(response.entity)
                setIsLoading(false)
            })
            
        }
        
      }, [router.isReady])
    return (
        <Brand brand={brandAssessment} isLoading={isLoading}/>
    )
}

export default Index
